import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { getImage } from '../utils';
import _ from 'lodash';
import {
  CategoryContainer,
  CategoryTitle,
  CategoryWrapper,
  CategoryList,
} from './templates.style';
import PostCardMinimal from '../components/post-card-minimal/post-card-minimal';
import Pagination from '../components/pagination/pagination';
import CategoryDetails from '../components/category-details';
import SEO from '../components/seo';
import Subscribe from '../containers/home/subscribe/subscribe';

const CategoryListPage = (props: any) => {
  const { data, pageContext } = props;
  const { category, currentPage, numPagesCat } = pageContext;
  const posts = data.allMdx.edges;

  const baseUrl = `/${_.kebabCase(category)}`;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPagesCat;
  const prevPage =
    currentPage - 1 === 1
      ? `${baseUrl}/page/1/#list`
      : `${baseUrl}/page/${(currentPage - 1).toString()}/#list`;
  const nextPage = `${baseUrl}/page/${(currentPage + 1).toString()}/#list`;
  const PrevLink = !isFirst && prevPage;
  const NextLink = !isLast && nextPage;

  const { mdx } = data;

  return (
    <Layout>
      <SEO title={`Page ${currentPage} | ${category} `} />
      <Subscribe isIndexPage={false} />
      <CategoryWrapper>
        <CategoryContainer>
          {mdx && <CategoryDetails body={mdx.body} />}
          <CategoryTitle>{category} Posts</CategoryTitle>
          <CategoryList>
            {posts.map(({ node }: any) => {
              const title = node.frontmatter.title || node.fields.slug;
              const postThumbnail =
                node.frontmatter.thumbnail != null
                  ? node.frontmatter.thumbnail
                  : node.frontmatter.videoThumbnail;
              const { image, imageType } = getImage(postThumbnail);

              return (
                <PostCardMinimal
                  key={node.fields.slug}
                  title={title}
                  image={image}
                  imageType={imageType}
                  url={node.fields.slug}
                  description={node.frontmatter.excerpt || node.excerpt}
                  date={node.frontmatter.date}
                  category={node.frontmatter.category}
                />
              );
            })}
          </CategoryList>
          <Pagination
            prevLink={PrevLink}
            nextLink={NextLink}
            currentPage={`${currentPage}`}
            totalPage={`${numPagesCat}`}
          />
        </CategoryContainer>
      </CategoryWrapper>
    </Layout>
  );
};

export default CategoryListPage;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $category: String!, $mdxFilter: String) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage {
      path
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$category] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            videoThumbnail {
              publicURL
              extension
            }
            thumbnail {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 167, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            category
          }
        }
      }
    }

    mdx(slug: { eq: $mdxFilter }) {
      body
      frontmatter {
        type
      }
    }
  }
`;
